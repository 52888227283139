<template>{{ frequencyLabel }}</template>

<script setup lang="ts">
const { t } = useI18n();

const props = defineProps({
  frequencyInDays: {
    type: Number,
    required: true,
  },
});

const frequencyLabel = computed(() => {
  const frequencyInWeeks = props.frequencyInDays / 7;

  if (frequencyInWeeks === 2) {
    return t('subscriptions.frequency_label.every_other_week');
  }

  return t('subscriptions.frequency_label.every_x_weeks', {
    weeks: frequencyInWeeks,
  });
});
</script>
